import { configureStore } from '@reduxjs/toolkit';
import searchSlice from '../data/api/Search/searchSlice';
import userDetailsSlice from '../data/api/UserDetails/userDetailsSlice';
import sidePanelSlice from '../data/api/SidePanel/sidePanelSlice';
import historySlice from '../data/api/History/historySlice';
import favoriteSlice from '../data/api/Favorite/favoriteSlice';
import qaSlice from '../data/api/QA/qaSlice';

const store = configureStore({
  reducer: {
    searchDetails: searchSlice,
    userDetails: userDetailsSlice,
    sidePanelDetails: sidePanelSlice,
    historyDetails: historySlice,
    favoriteDetails: favoriteSlice,
    qaDetails: qaSlice
  },
});

export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
