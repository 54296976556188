import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { serializeError } from "serialize-error";
import { RawRequestError, fetchGetApi } from "../../../api/fetch";
import { UserDetails } from "../../../types";
import { USER_DATA_URL } from "../../../constants";

type InitialState = {
  isLoading: boolean;
  user: UserDetails;
  error: RawRequestError | null;
};

const initialState: InitialState = {
  isLoading: false,
  user: {} as UserDetails,
  error: null,
};

// fetch user details
const fetchUserDetails = createAsyncThunk(
  "login/fetchUserDetails",
  async (_: void, thunkAPI) =>
    fetchGetApi(USER_DATA_URL)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)))
);

const searchSlice = createSlice({
  name: "login/fetchUserDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserDetails.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
      const { user } = action.payload;
      return {
        ...state,
        isLoading: false,
        user: {
          firstName: user.firstname,
          lastName: user.lastname,
          email: user.email,
        },
        error: null,
      };
    });
    builder.addCase(fetchUserDetails.rejected, (state, action) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { user } = action.payload;
      return {
        ...state,
        isLoading: false,
        user: {
          firstName: user?.firstname,
          lastName: user?.lastname,
          email: user?.email,
        },
        error: action.payload as RawRequestError,
      };
    });
  },
});

export default searchSlice.reducer;
export { fetchUserDetails, initialState };
