import { CsvIcon } from '../../svg/csv';
import { DefaultDocIcon } from '../../svg/defaultDoc';
import { DocIcon } from '../../svg/doc';
import { HtmlIcon } from '../../svg/html';
import { PdfIcon } from '../../svg/pdf';
import { PngIcon } from '../../svg/png';
import { PptIcon } from '../../svg/ppt';
import { PptxIcon } from '../../svg/pptx';
import { TxtIcon } from '../../svg/txt';
import { XlsxIcon } from '../../svg/xlsx';

type FileProps = {
  url: string;
};

function FileIcon({ url }: FileProps) {
  const lowerCaseUrl = url.toLowerCase();

  switch (true) {
    case lowerCaseUrl.includes('csv'):
      return <CsvIcon />;
    case lowerCaseUrl.includes('doc'):
      return <DocIcon />;
    case lowerCaseUrl.includes('html'):
      return <HtmlIcon />;
    case lowerCaseUrl.includes('pdf'):
      return <PdfIcon />;
    case lowerCaseUrl.includes('png'):
      return <PngIcon />;
    case lowerCaseUrl.includes('pptx'):
      return <PptxIcon />;
    case lowerCaseUrl.includes('ppt'):
      return <PptIcon />;
    case lowerCaseUrl.includes('txt'):
      return <TxtIcon />;
    case lowerCaseUrl.includes('xlsx'):
      return <XlsxIcon />;
    default:
      return <DefaultDocIcon />;
  }
}

export default FileIcon;
