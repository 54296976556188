import cookies from 'js-cookie';
import { UserDetails } from '../types';
import { MSW_BASE_URL } from '../constants';

interface RawRequestError {
  message: string;
  code: number;
  user?: UserDetails;
}

class ApiRequestError extends Error implements RawRequestError {
  message: string;

  code: number;

  user?: UserDetails;

  constructor(jsonResponse: RawRequestError = {} as RawRequestError) {
    const {
      message = '',
      code = 0,
      user,
    } = jsonResponse;

    super();
    this.message = message;
    this.code = code;
    this.user = user;
  }
}

const checkEnvironment = (endpoint: string) => {
  const url = process.env.NODE_ENV === 'test'
    ? `${MSW_BASE_URL}${endpoint}`
    : `/api/${endpoint}`;

  return url;
};

const fetchPostApi = async <T>(params: T, endpoint: string) => {
  const res = await fetch(checkEnvironment(endpoint), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': cookies.get('csrfToken') || '',
    },
    body: JSON.stringify(params),
  });

  if (!res.ok) {
    const errorResult = await res.json();
    if (!errorResult.code) {
      errorResult.code = res.status;
    }

    throw new ApiRequestError(errorResult);
  }

  return res.json();
};

const fetchPutApi = async <T>(params: T, endpoint: string) => {
  const res = await fetch(checkEnvironment(endpoint), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': cookies.get('csrfToken') || '',
    },
    body: JSON.stringify(params),
  });

  if (!res.ok) {
    const errorResult = await res.json();
    if (!errorResult.code) {
      errorResult.code = res.status;
    }

    throw new ApiRequestError(errorResult);
  }

  return res.json();
};

const fetchGetApi = async (endpoint: string) => {
  const res = await fetch(checkEnvironment(endpoint), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!res.ok) {
    const errorResult = await res.json();
    if (!errorResult.code) {
      errorResult.code = res.status;
    }

    throw new ApiRequestError(errorResult);
  }

  return res.json();
};

export {
  fetchPostApi, fetchGetApi, checkEnvironment, fetchPutApi, ApiRequestError,
};
export type { RawRequestError };
