function FilterIcon(){
return(
<svg width="30px" height="24px" viewBox="0 0 30 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>532B989B-1796-4595-BEA0-62015D566B57</title>
    <g id="ICE-V.2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Q&amp;A" transform="translate(-836, -774)" fill="#000000" fill-rule="nonzero">
            <g id="filter-icon" transform="translate(836, 774)">
                <path d="M20,0 C22.4185018,0 24.4359508,1.71711201 24.8997197,3.99870919 L29,4 C29.5522847,4 30,4.47969491 30,5.07142858 C30,5.59741406 29.6462496,6.03487509 29.1797514,6.125595 L29,6.14285715 L24.8685934,6.14369274 C24.3513887,8.35396089 22.3679209,10 20,10 C17.6320791,10 15.6486113,8.35396089 15.1314066,6.14369274 L1,6.14285715 C0.447715243,6.14285715 0,5.66316223 0,5.07142858 C0,4.5454431 0.353750314,4.10798207 0.820248729,4.01726216 L1,4 L15.1002803,3.99870919 C15.5640493,1.71711201 17.5814982,0 20,0 Z M20,1.99999999 C18.3431457,1.99999999 17,3.34314574 17,4.99999999 C17,6.65685424 18.3431457,7.99999999 20,7.99999999 C21.6568543,7.99999999 23,6.65685424 23,4.99999999 C23,3.34314574 21.6568543,1.99999999 20,1.99999999 Z M10,14 C12.4192343,14 14.4371727,15.7181523 14.9001407,18.0007824 L29,18 L29.1797514,18.0172622 C29.6462496,18.107982 30,18.5454431 30,19.0714286 C30,19.6631623 29.5522847,20.1428571 29,20.1428571 L14.8680903,20.1458404 C14.3501036,22.3550271 12.3671538,24 10,24 C7.63284619,24 5.6498964,22.3550271 5.13190965,20.1458404 L1,20.1428571 L0.820248729,20.1255949 C0.353750314,20.0348751 0,19.5974141 0,19.0714286 C0,18.4796949 0.447715243,18 1,18 L5.09985936,18.0007824 C5.56282729,15.7181523 7.58076566,14 10,14 Z M10,16 C8.34314576,16 7.00000001,17.3431457 7.00000001,19 C7.00000001,20.6568542 8.34314576,22 10,22 C11.6568543,22 13,20.6568542 13,19 C13,17.3431457 11.6568543,16 10,16 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>
    );
}

export {FilterIcon}