import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RawRequestError, fetchGetApi, fetchPostApi, fetchPutApi } from '../../../api/fetch';
import { CHAT } from '../../../constants';
import { serializeError } from 'serialize-error';

export type QAChatParams = {
  query: string;
  data_set: QADataSet;
  session_id?: string;
  files?: File[];
}

export enum QADataSet {
  /* Internal = "internal", */
  External = "external"
}

type QAReqest = {
  query: string;
  session_id?: string;
  response_config: any;
  dataset_type: QADataSet;
}

export type QAFileInput = {
  name: string;
  url: string;
  type: string;
}

type QAChatResponse = {
  session_id?: string;
  question_id?: string;
  query?: string;
  answer?: string;
  files?: QAFileInput[];
  rating: string | null;
  date_time: string;
}

type QAHistoryResult = {
  session_id: string;
  query: string;
  date_time: string;
}

export type QAHistoryResponse = {
  result: QAHistoryResult[];
}

type QAFavoritesResponse = QAHistoryResponse;

type ChatQuestionData = {
  question_id: string;
  query: string;
  answer: string;
  date_time: string;
  rating: string | null;
}

type GetChatBySessionIdResponse = {
  session_id: string;
  question_data: ChatQuestionData[];
  favorite: boolean;
}

type InitialState = {
  isLoading: boolean,
  isFavorite: boolean,
  isLoadingFavorite: boolean;
  ratingResults: Array<{questionId: string, isLoading: boolean, error: RawRequestError | null}>
  chatResults: QAChatResponse[],
  historyResults: QAHistoryResponse;
  favoritesResults: QAFavoritesResponse;
  answerError: RawRequestError | null,
  favoriteError: RawRequestError | null,
  getHistoryError: RawRequestError | null,
  getFavoritesError: RawRequestError | null,
  getChatBySessionIdError: RawRequestError | null
};

type QAFavoriteParams = {
  sessionId: string;
  favorite: boolean;
}

type QARatingParams = {
  sessionId: string;
  questionId: string;
  rating: number;
}

const initialState: InitialState = {
  isLoading: false,
  isFavorite: false,
  isLoadingFavorite: false,
  ratingResults: [],
  chatResults: [],
  historyResults: {result: []},
  favoritesResults: {result: []},
  answerError: null,
  favoriteError: null,
  getHistoryError: null,
  getFavoritesError: null,
  getChatBySessionIdError: null
};


const updateQAFavorite = createAsyncThunk(
  'qa/updateQAFavorite',
  async (params: QAFavoriteParams, thunkAPI) => {
    const {
      favorite,
      sessionId
    } = params;

    const favoriteEndpoint = `chat/${sessionId}/favorite?favorite=${favorite}`;
    return fetchPutApi({}, favoriteEndpoint)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  },
);

const getQAHistory = createAsyncThunk(
  'qa/getQAHistory',
  async (_: void, thunkAPI) => {

    console.log("get qa history");
    const endpoint = `chat/history`;
    
    return fetchGetApi(endpoint)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
    
  },
);

const getQAFavorites = createAsyncThunk(
  'qa/getQAFavorites',
  async (_: void, thunkAPI) => {

    const endpoint = `chat/favorite`;

    return fetchGetApi(endpoint)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
      
  },
);

const getChatBySessionId = createAsyncThunk(
  'qa/getChatBySessionId',
  async (params: {sessionId: string}, thunkAPI) => {

    const endpoint = `chat/${params.sessionId}`;

    return fetchGetApi(endpoint)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
    
  },
);

const updateQARating = createAsyncThunk(
  'qa/updateQARating',
  async (params: QARatingParams, thunkAPI) => {
    const {
      questionId,
      sessionId,
      rating
    } = params;

    console.log("update qa rating " + rating);
    thunkAPI.dispatch(setRatingResult({questionId, isLoading: true, error: null}));
    const favoriteEndpoint = `chat/${sessionId}/question/${questionId}/rating?rating=${rating}`;
    return fetchPutApi({}, favoriteEndpoint)
      .then((response) => thunkAPI.fulfillWithValue({questionId, response}))
      .catch((e: Error) => thunkAPI.rejectWithValue({questionId, error: serializeError(e)}));
  }
);

// Generates pending, fulfilled and rejected action types
const getChat = createAsyncThunk(
  'qa/getChat',
  async (params: QAChatParams, thunkAPI) => {

    const reqData: QAReqest = {
      query: params.query,
      response_config: {
        "creativity": "liberal",
        "length": "verbose",
        "speed": "normal"
      },
      dataset_type: params.data_set,
      session_id: params.session_id ?? ""
    };

    return fetchPostApi(reqData, CHAT)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  },
);


const qaSlice = createSlice({
  name: 'qa',
  initialState,
  reducers: {
    setChat: (state, action) => {
      state.chatResults = [...state.chatResults, action.payload];
    },
    clearChat: (state) => {
      return {
        ...initialState,
        favoritesResults: state.favoritesResults,
        historyResults: state.historyResults
      };
    },
    setQAFavorite: (state, action) => {
      state.isFavorite = action.payload;
    },
    setRatingResult: (state, action) => {
      const filteredRatings = state.ratingResults.filter( (rating) => rating.questionId !== action.payload.questionId);
      state.ratingResults = [...filteredRatings, action.payload];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getChat.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(
      getChat.fulfilled,
      (state, action) => {
        const { data } = action.payload as any;
        let historyResults = state.historyResults;
        const existsInHistory = historyResults.result.find( (history) => history.session_id === data.session_id);
        if (!existsInHistory) {
          const history = {
            session_id: data.session_id,
            query: data.query,
            date_time: data.date_time
          };
          historyResults = {result: [...state.historyResults.result, history]};
        }
        // Remove the last element and replace it with the new response that contains an answer
        const filteredChatResults = state.chatResults.filter( (_, index) => index !== state.chatResults.length - 1);
        return {
          ...state,
          isLoading: false,
          chatResults: [...filteredChatResults, data],
          historyResults,
          answerError: null,
        } as any;
      },
    );
    builder.addCase(getChat.rejected, (state, action) => ({
      ...state,
      isLoading: false,
      answerError: action.payload as RawRequestError,
    }));
    builder.addCase(updateQAFavorite.pending, (state) => ({
      ...state,
      isLoadingFavorite: true,
    }));
    builder.addCase(updateQAFavorite.fulfilled, (state) => {
      let favoritesResults = state.favoritesResults;
      if (state.chatResults && state.chatResults.length > 0) {
        const firstChat = state.chatResults[0];
        const existsInFavorites = state.favoritesResults.result.find( (favorite) => favorite.session_id === firstChat.session_id);
        if (!existsInFavorites && state.isFavorite) {
          const favorite = {
            session_id: firstChat.session_id!,
            query: firstChat.query!,
            date_time: firstChat.date_time!
          };
          favoritesResults = {result: [...state.favoritesResults.result, favorite]};
        } else if (existsInFavorites && !state.isFavorite) {
          const filteredFavorites = state.favoritesResults.result.filter( (favorite) => favorite.session_id !== firstChat.session_id);
          favoritesResults = {result: [...filteredFavorites]};
        }
      }
      return {
        ...state,
        favoritesResults,
        isLoadingFavorite: false 
      }
    });
    builder.addCase(updateQAFavorite.rejected, (state, action) => {
      return {
        ...state,
        isFavorite: !state.isFavorite,
        isLoadingFavorite: false,
        favoriteError: action.payload as RawRequestError,
      }
    });
    builder.addCase(updateQARating.fulfilled, (state, action) => {
      const questionId = action.payload.questionId;
      state.ratingResults = state.ratingResults.map( (ratingResult) => {
        if (ratingResult.questionId === questionId) {
          return {
            ...ratingResult,
            isLoading: false,
            error: null
          }
        }
        return ratingResult;
      })
    });
    builder.addCase(updateQARating.rejected, (state, action) => {
      const questionId = (action.payload as any).questionId;
      state.ratingResults = state.ratingResults.map( (ratingResult) => {
        if (ratingResult.questionId === questionId) {
          return {
            ...ratingResult,
            isLoading: false,
            error: (action.payload as any).error
          }
        }
        return ratingResult;
      })
    });
    builder.addCase(
      getQAHistory.fulfilled,
      (state, action) => {
        const { data } = action.payload as any;
        return {
          ...state,
          historyResults: data,
          getHistoryError: null,
        } as any;
      },
    );
    builder.addCase(
      getQAFavorites.fulfilled,
      (state, action) => {
        const { data } = action.payload as any;
        return {
          ...state,
          favoritesResults: data,
          getFavoritesError: null,
        } as any;
      },
    );
    builder.addCase(
      getQAHistory.rejected,
      (state, action) => {
        return {
          ...state,
          getHistoryError: action.payload as RawRequestError,
        }
      },
    );
    builder.addCase(
      getQAFavorites.rejected,
      (state, action) => {
        return {
          ...state,
          getFavoritesError: action.payload as RawRequestError,
        }
      },
    );
    builder.addCase(
      getChatBySessionId.fulfilled,
      (state, action) => {
        const response = action.payload.data as GetChatBySessionIdResponse;
        const mappedResults: QAChatResponse[] = response.question_data.map( (data: ChatQuestionData) => {
          return {
            session_id: response.session_id,
            question_id: data.question_id,
            query: data.query,
            answer: data.answer,
            rating: data.rating,
            date_time: data.date_time
          }
        })
        return {
          ...state,
          chatResults: mappedResults,
          isFavorite: response.favorite,
          getChatBySessionIdError: null
        } as any;
      },
    );
    builder.addCase(
      getChatBySessionId.rejected,
      (state, action) => {
        return {
          ...state,
          getChatBySessionIdError: action.payload as RawRequestError,
        }
      },
    );
  },
});

const { setChat, clearChat, setQAFavorite, setRatingResult } = qaSlice.actions;
export default qaSlice.reducer;
export {
  setChat,
  clearChat,
  setQAFavorite,
  getChat,
  updateQAFavorite,
  updateQARating,
  setRatingResult,
  getQAFavorites,
  getQAHistory,
  getChatBySessionId
};
