import { useState } from "react";
import "./index.css";
import { FAVOURITE_SIDE_TAB, HISTORY_SIDE_TAB } from "../../../constants";
import { RootState } from "../../../redux/store";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
// import { getSearch } from '../../../data/api/GetSearch/getSearchSlice';
import { getSearch } from "../../../data/api/Search/searchSlice";
import { GetSearch, setGetSearch } from "../../../data/api/Search/searchSlice";

function History() {
  const [selectedTab, setSelectedTab] = useState(HISTORY_SIDE_TAB);
  const { favorites } = useAppSelector(
    (state: RootState) => state.searchDetails
  );
  const { history } = useAppSelector((state: RootState) => state.searchDetails);
  // const resultsRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  let favoritesresultObj: any = {};
  let historyresultObj: any = {};
  if (favorites.length > 0) {
    for (let i = 0; i < favorites.length; i++) {
      let parsedDate = favorites[i].date_time.toString().substring(0, 10);
      if (!favoritesresultObj[parsedDate]) {
        favoritesresultObj[parsedDate] = [favorites[i]];
      } else {
        favoritesresultObj[parsedDate] = [
          ...favoritesresultObj[parsedDate],
          favorites[i],
        ];
      }
    }
  }

  const fetchGetSearch = (search_id: string) => {
    dispatch(getSearch(search_id));
    dispatch(setGetSearch(GetSearch.historyfav));
  };

  if (history.length > 0) {
    for (let i = 0; i < history.length; i++) {
      let parsedDate = history[i].date_time.substring(0, 10);
      if (!historyresultObj[parsedDate]) {
        historyresultObj[parsedDate] = [history[i]];
      } else {
        historyresultObj[parsedDate] = [
          ...historyresultObj[parsedDate],
          history[i],
        ];
      }
    }
  }

  return (
    <div className="history-tab">
      <div className="history-tab-header">
        <span className="history-tab-heading">
          {" "}
          {selectedTab === HISTORY_SIDE_TAB
            ? "Search History"
            : "Search Favorites"}{" "}
        </span>
        <span
          className={`history-tab-toggle cursor-pointer ${
            selectedTab === FAVOURITE_SIDE_TAB ? "active" : "inactive"
          }`}
          onClick={() => setSelectedTab(FAVOURITE_SIDE_TAB)}
        >
          Favorite
        </span>
        <span
          className={`history-tab-toggle cursor-pointer ${
            selectedTab === HISTORY_SIDE_TAB ? "active" : "inactive"
          }`}
          onClick={() => setSelectedTab(HISTORY_SIDE_TAB)}
        >
          History
        </span>
        <span className="history-tab-subheading">
          Select a prompt below to view the results
        </span>
      </div>
      <div className="history-tab-content">
        {selectedTab === HISTORY_SIDE_TAB ? (
          <>
            {Object.keys(historyresultObj).map((key) => {
              return (
                <>
                  <div className="date">
                    {new Date(key).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </div>
                  <div>
                    {historyresultObj[key].map((el: any) => {
                      return (
                        <div
                          className="history-tab-prompt d-flex flex-row p-6 questions cursor-pointer"
                          onClick={() => fetchGetSearch(el.search_id)}
                        >
                          {el.query}
                        </div>
                      );
                    })}
                  </div>
                </>
              );
            })}
          </>
        ) : (
          <>
            {Object.keys(favoritesresultObj).map((key) => {
              return (
                <>
                  <div className="date">
                    {new Date(key).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </div>
                  <div>
                    {favoritesresultObj[key].map((el: any) => {
                      return (
                        <div
                          className="history-tab-prompt d-flex flex-row p-6 questions cursor-pointer"
                          onClick={() => fetchGetSearch(el.search_id)}
                        >
                          {el.query}
                        </div>
                      );
                    })}
                  </div>
                </>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}

export default History;
