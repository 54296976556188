const BASE_URL = 'http://192.168.1.9:8000/';

const PREDICT = 'search';

const CHAT = 'chat';

const MSW_BASE_URL = 'https://gcpllm.oclc.org/api/';

const USER_DATA_URL = 'userdetails';

const HISTORY_URL = 'gethistory';

const FAVORITES_URL = 'getfavorites';

const GET_SEARCH_URL = 'getsearch'

const ERROR_MESSAGE_500 = 'An error has occurred. Please try again later';

const SUPPORTED_IMAGE_TYPES = ["jpg", "jpeg", "png", "svg"];

const SUPPORTED_DOCUMENT_TYPES = ["doc", "docx", "pdf", "xls", "xlsx", "txt"];

export {
  BASE_URL,
  ERROR_MESSAGE_500,
  MSW_BASE_URL,
  PREDICT,
  USER_DATA_URL,
  HISTORY_URL,
  FAVORITES_URL,
  GET_SEARCH_URL,
  CHAT,
  SUPPORTED_IMAGE_TYPES,
  SUPPORTED_DOCUMENT_TYPES
};
