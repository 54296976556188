import {
  Form,
  InputGroup,
  Container,
  Button,
  Stack,
  Dropdown,
  Alert,
  Spinner,
} from "react-bootstrap";
import "./index.css";
import { FilterIcon } from "../../svg/filterIcon";
import { NewChatButton } from "../../svg/newChatButton";

import { useEffect, useRef, useState } from "react";
import { PromptSearch } from "../../svg/promptSearch";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import AnswerBubble from "../../components/AnswerBubble";
import QuestionBubble from "../../components/QuestionBubble";
import {
  QADataSet,
  QAChatParams,
  clearChat,
  getChat,
  setChat,
  setQAFavorite,
  updateQAFavorite,
} from "../../data/api/QA/qaSlice";
import {
  MAX_CHARACTERS,
  SUPPORTED_DOCUMENT_TYPES,
  SUPPORTED_IMAGE_TYPES,
} from "../../constants";
import { FilterIconSelected } from "../../svg/filterIconSelected";
import { FileDrop } from "react-file-drop";
//import { AddFileSelected } from '../../svg/addFileSelected';
//import { AddFile } from '../../svg/addFile';
import FileInput from "../../components/FIleInput";
import { getFileExtension } from "../../utils/file";
import { DefaultFavourite } from "../../svg/defaultFavorite";
import { QASplash } from "../../svg/qaSplash";

function Questions() {
  const dispatch = useAppDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [question, setQuestion] = useState<string>("");
  const [showFilter, setShowFilter] = useState(false);
  const [showAddFile, setShowAddFile] = useState(false);
  const [dataSet, setDataSet] = useState(QADataSet.External);
  const [isErrorInInput, setIsErrorinInput] = useState(false);
  const [fileInputs, setFileInputs] = useState<File[]>([]);
  const bubblesRef = useRef<HTMLDivElement | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const characterCountDisplay = `${question.length}/${MAX_CHARACTERS}`;
  const {
    chatResults,
    isFavorite,
    isLoading,
    isLoadingFavorite,
    favoriteError,
    answerError,
    getChatBySessionIdError,
  } = useAppSelector((state: RootState) => state.qaDetails);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      submitQuestion();
    }
  };

  const scrollToBottom = () => {
    if (bubblesRef.current) {
      const scrollHeight = bubblesRef.current.scrollHeight;
      bubblesRef.current.scrollTop = scrollHeight;
    }
  };

  useEffect(() => {
    if (favoriteError) {
      setShowAlert(true);
      setErrorMessage("Unable to set favorite");
    }
  }, [favoriteError]);

  useEffect(() => {
    if (answerError) {
      setShowAlert(true);
      setErrorMessage("Unable to fetch answer");
    }
  }, [answerError]);

  useEffect(() => {
    if (getChatBySessionIdError) {
      setShowAlert(true);
      setErrorMessage("Unable to load messages");
    }
  }, [getChatBySessionIdError]);

  useEffect(() => {
    if (chatResults) {
      console.log("answer results " + JSON.stringify(chatResults));
      const newSessionId = chatResults.find(
        (result) => !!result.session_id
      )?.session_id;
      setSessionId(newSessionId ?? null);
      scrollToBottom();
    }
  }, [chatResults]);

  useEffect(() => {
    if (textareaRef.current && question !== "") {
      adjustTextareaHeight();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const resetTextareaSize = () => {
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.style.resize = "both";
        textareaRef.current.style.height = "auto";
      }
    }, 10);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentCharacterCount = e.target.value.length;
    if (currentCharacterCount <= MAX_CHARACTERS) {
      setQuestion(e.target.value);
      setIsErrorinInput(false);
    } else {
      setIsErrorinInput(true);
    }
  };

  const submitQuestion = () => {
    if (question) {
      const qaFiles = fileInputs.map((file: File) => {
        return {
          name: file.name,
          url: URL.createObjectURL(file),
        };
      });
      dispatch(
        setChat({
          query: question,
          files: qaFiles,
        })
      );
      const params: QAChatParams = {
        query: question,
        files: fileInputs,
        data_set: dataSet,
      };
      if (sessionId) {
        params.session_id = sessionId;
      }
      dispatch(getChat(params));
      setQuestion("");
      setFileInputs([]);
      setShowAddFile(false);
      setIsErrorinInput(false);
      resetTextareaSize();
    }
  };

  const onFileDropClick = () => {
    fileInputRef?.current?.click();
  };

  const onFileInputChange = (event: React.ChangeEvent) => {
    const { files } = event.target as any;
    if (files) {
      processFiles(files);
    }
  };

  const onFileDrop = (files: FileList | null) => {
    console.log("got files");
    if (files) {
      processFiles(files);
    }
  };

  const processFiles = (files: FileList) => {
    Array.from(files).forEach((file) => {
      console.log(file.type);
      const fileExtension = getFileExtension(file.name);
      if (fileExtension) {
        const isFileTypeSupported =
          SUPPORTED_IMAGE_TYPES.includes(fileExtension) ||
          SUPPORTED_DOCUMENT_TYPES.includes(fileExtension);
        if (isFileTypeSupported && fileInputs.length <= 1) {
          // Need to add slight delay before removing the drag and drop field otherwise
          // the file will be opened by the browser
          setTimeout(() => {
            setFileInputs([...fileInputs, file]);
          }, 100);
        }
      }
    });
  };
  /*
  const onAddFileClick = () => {
    setShowAddFile(!showAddFile);
  };
  */

  const onRemoveFile = (file: File) => {
    setFileInputs(fileInputs.filter((f) => f !== file));
  };

  const onNewChatClick = () => {
    setSessionId(null);
    dispatch(clearChat());
  };

  const getDataSetLabel = (dataSet: QADataSet) => {
    switch (dataSet) {
      /* case QADataSet.Internal:
        return "Jira and Zendesk Combined"; */
      case QADataSet.External:
        return "External";
    }
  };

  const onFavoriteClick = () => {
    if (sessionId) {
      dispatch(setQAFavorite(!isFavorite));
      dispatch(updateQAFavorite({ sessionId, favorite: !isFavorite }));
    }
  };

  // const [chatData] = useState(false);
  return (
    <Container>
      <div className="qa-container d-flex justify-content-center">
        <div className="qa-subcontainer">
          {chatResults && chatResults.length > 0 && (
            <div className="bubbles-container flex-grow-1 d-flex flex-column justify-content-end">
              <div className="bubble-scroll-container" ref={bubblesRef}>
                {chatResults.map((result) => {
                  if (result.answer) {
                    return (
                      <div key={result.question_id}>
                        <QuestionBubble
                          question={result.query!}
                          files={result.files}
                        />
                        <div className="qa-spacer"></div>
                        <AnswerBubble
                          answer={result.answer}
                          sessionId={result.session_id}
                          questionId={result.question_id}
                          rating={result.rating}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <QuestionBubble
                        key={result.question_id}
                        question={result.query!}
                        files={result.files}
                      />
                    );
                  }
                })}
                {isLoading && (
                  <div className="w-100 d-flex justify-content-center spinner-container">
                    <Spinner animation="border" variant="primary" />
                  </div>
                )}
              </div>
            </div>
          )}

          {(!chatResults || chatResults.length === 0) && (
            <div
              data-testid="qa-welcome"
              className="w-100 flex-grow-1 d-flex align-items-center justify-content-center welcome-container"
            >
              <div>
                <div className="welcome-txt">
                  Welcome to <span>OCLC Q&A</span>,<br />
                  below are some examples of information you may request
                </div>
                <QASplash />
              </div>
            </div>
          )}

          {showAlert && (
            <div data-testid="qa-alert">
              <Alert
                variant="danger"
                onClose={() => setShowAlert(false)}
                dismissible
              >
                <Alert.Heading>Error</Alert.Heading>
                <p>{errorMessage}</p>
              </Alert>
            </div>
          )}

          <section id="inputgroup" className="textarea-container">
            <InputGroup className="form d-flex align-items-start textarea-input-group">
              {showAddFile && (
                <div className="file-drop-container w-100 d-flex">
                  {fileInputs.length > 0 && (
                    <>
                      <div className="file-input-container h-100">
                        <FileInput
                          file={fileInputs[0]}
                          onRemoveFile={onRemoveFile}
                        />
                      </div>
                      <div className="file-input-divider"></div>
                    </>
                  )}
                  {fileInputs.length > 1 && (
                    <div className="file-input-container h-100">
                      <FileInput
                        file={fileInputs[1]}
                        onRemoveFile={onRemoveFile}
                      />
                    </div>
                  )}
                  {fileInputs.length <= 1 && (
                    <div
                      className="file-drop-box h-100 flex-grow-1"
                      onClick={onFileDropClick}
                    >
                      <FileDrop onDrop={onFileDrop}>
                        Drag and drop an image or document or click to browse
                        files
                      </FileDrop>
                      <input
                        onChange={onFileInputChange}
                        ref={fileInputRef}
                        type="file"
                        className="file-input"
                      />
                    </div>
                  )}
                </div>
              )}
              <Button
                className="transparent-button new-chat-button"
                variant="primary"
                id="new-chat"
                data-testid="new-chat"
                onClick={onNewChatClick}
              >
                <NewChatButton />
              </Button>
              &nbsp; &nbsp;
              <Stack>
                <div className="d-flex textarea-buttons-container">
                  {/*
                <Button
                    className="add-image-button"
                    variant="primary"
                    id="add-image"
                    data-testid="add-image"
                    onClick={onAddFileClick}
                  >
                    {
                      (showAddFile ? <AddFileSelected /> : <AddFile />)
                    }
                </Button>
                */}
                  <Form.Control
                    as="textarea"
                    ref={textareaRef}
                    rows={1}
                    className={
                      isErrorInInput ? "is-invalid qa-textarea" : "qa-textarea"
                    }
                    placeholder="Ask me anything..."
                    data-testid="qa-input"
                    onKeyDown={handleKeyPress}
                    onChange={handleChange}
                    disabled={isLoading}
                    value={question}
                  />
                  <Button
                    className="submit"
                    variant="primary"
                    id="submit-search"
                    data-testid="submit-search"
                    disabled={isLoading}
                    onClick={submitQuestion}
                  >
                    <PromptSearch />
                  </Button>
                </div>
                <div className="d-flex justify-content-between text-bottom-container">
                  <div>Data Sets: {getDataSetLabel(dataSet)}</div>
                  <div
                    className={`${
                      isErrorInInput ? "question-character-count-error" : ""
                    }`}
                  >
                    {characterCountDisplay}
                  </div>
                </div>
              </Stack>
              &nbsp; &nbsp;
              <Button
                className="transparent-button filter-button"
                onClick={() => setShowFilter((prevState) => !prevState)}
              >
                {showFilter ? <FilterIconSelected /> : <FilterIcon />}
              </Button>
              <Dropdown
                show={showFilter}
                onToggle={() => setShowFilter((prevState) => !prevState)}
              >
                <Dropdown.Menu role="menuitemradio" className="qa-dropdown">
                  <Dropdown.Header className="dropdown-header-class">
                    Q&A Filter
                  </Dropdown.Header>
                  <Dropdown.Header className="dropdown-subheader">
                    Select Data Set
                  </Dropdown.Header>
                  {Object.values(QADataSet).map((ds, index) => {
                    const isDefaultChecked = ds === QADataSet.External;
                    return (
                      <Dropdown.Item className="text-info" eventKey={index}>
                        <div
                          key="radio"
                          className="mb-3"
                          onClick={() => setDataSet(ds)}
                        >
                          <Form.Check
                            className="dropdown-contents"
                            checked={dataSet == ds}
                            onChange={() => setDataSet(ds)}
                            defaultChecked={isDefaultChecked}
                            label={getDataSetLabel(ds)}
                            name="radiobutton"
                            type="radio"
                            id={`inline-1`}
                          />
                        </div>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
              &nbsp; &nbsp;
              <Button
                className="transparent-button fav-button"
                onClick={onFavoriteClick}
                disabled={!sessionId || isLoadingFavorite}
              >
                <DefaultFavourite
                  className={
                    isFavorite ? "checked-favourite" : "unchecked-favourite"
                  }
                />
              </Button>
            </InputGroup>
          </section>
        </div>
      </div>
    </Container>
  );
}

export default Questions;
