import { Button } from 'react-bootstrap';
import { useState } from 'react';
import { DefaultRatingStar } from '../../svg/deafultRatingStar';

import './index.css';

type RatingProps = {
  rating: number;
  setRating: (rating: number) => void;
  disabled?: boolean;
}

type DefaultRatingProps = {
  rating: number;
}

function QARating({ rating, setRating, disabled }: RatingProps) {

  const [showDefaultRating, setDefaultRating] = useState(true);
  const [currentHoveringRating, setCurrentHoveringRating] = useState(0);
  const [currentRatingClass, setCurrentRatingClass] = useState('rating-none');

  function DefaultRating({ rating }: DefaultRatingProps, ) {
    if (!rating) {
      return <DefaultRatingStar id="star-default" className="rating-none" />;
    } else {
      let ratingClass = '';

      switch (rating) {
        case 1: ratingClass = "rating-one";
          break;
        case 2: ratingClass = "rating-two";
          break;
        case 3: ratingClass = "rating-three";
          break;
        case 4: ratingClass = "rating-four";
          break;
        case 5: ratingClass = "rating-five";
          break;
        default:
          break;
      }
      return <DefaultRatingStar id="star-default" className={ratingClass} />;
    }
  };

  function SetRating({setRating}: RatingProps) {
    const mouseEnterForOneRating = () => {
      setCurrentHoveringRating(1);
      setCurrentRatingClass('rating-one');
    };

    const mouseEnterForTwoRating = () => {
      setCurrentHoveringRating(2);
      setCurrentRatingClass('rating-two');
    };

    const mouseEnterForThreeRating = () => {
      setCurrentHoveringRating(3);
      setCurrentRatingClass('rating-three');
    };

    const mouseEnterForFourRating = () => {
      setCurrentHoveringRating(4);
      setCurrentRatingClass('rating-four');
    };

    const mouseEnterForFiveRating = () => {
      setCurrentHoveringRating(5);
      setCurrentRatingClass('rating-five');
    };

    return (
      <>
        <span onMouseEnter={() => mouseEnterForOneRating()} onClick={()=> setRating(1)}><DefaultRatingStar id="star-one" className={currentHoveringRating >= 1 ? currentRatingClass : 'rating-none'} /></span>
        <span onMouseEnter={() => mouseEnterForTwoRating()} onClick={()=> setRating(2)}><DefaultRatingStar id="star-two" className={currentHoveringRating >= 2 ? currentRatingClass : 'rating-none'} /></span>
        <span onMouseEnter={() => mouseEnterForThreeRating()} onClick={()=> setRating(3)}><DefaultRatingStar id="star-three" className={currentHoveringRating >= 3 ? currentRatingClass : 'rating-none'} /></span>
        <span onMouseEnter={() => mouseEnterForFourRating()} onClick={()=> setRating(4)}><DefaultRatingStar id="star-four" className={currentHoveringRating >= 4 ? currentRatingClass : 'rating-none'} /></span>
        <span onMouseEnter={() => mouseEnterForFiveRating()} onClick={()=> setRating(5)}><DefaultRatingStar id="star-five" className={currentHoveringRating >= 5 ? currentRatingClass : 'rating-none'} /></span>
      </>
    )
  };

  const setPromptRating = (rating: number) => {
    setRating(rating);
    setDefaultRating(true);
  };

  return (
    <div className="align-rating">
      <Button
        className="rating-btn"
        disabled={disabled !== undefined ? disabled : false}
        onMouseEnter={() => setDefaultRating(false)}
        onMouseLeave={() => setDefaultRating(true)}
      >
        {
          showDefaultRating ? <DefaultRating rating={rating} /> : <SetRating setRating={setPromptRating} rating={rating} />
        }

      </Button>

    </div>
  )
}

export {
  QARating
}