import { useEffect } from "react";
import { RootState } from "./redux/store";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
//import Search from "./pages/SearchPage";
import Questions from "./pages/QuestionsAnswersPage";
import NavbarComponent from "./components/NavbarComponent";
import { fetchUserDetails } from "./data/api/UserDetails/userDetailsSlice";
// import { fetchHistory } from './data/api/History/historySlice';
import { fetchHistory } from "./data/api/Search/searchSlice";
// import { fetchFavorites } from './data/api/Favorite/favoriteSlice';
import { fetchFavorites } from "./data/api/Search/searchSlice";
import SidePanel from "./components/SidePanel";
import SidePanelMobileView from "./components/SidePanelMobileView";
import ModalPopup from "./components/Modal";
//import Settings from './components/Settings';
import { Tab, Tabs } from "react-bootstrap";
import {
  setIsSidePanelActiveMobileView,
  setIsSidePanelActive,
  SidePanelState,
  setSidePanelState,
} from "./data/api/SidePanel/sidePanelSlice";
import { ClosePanel } from "./svg/closePanel";
import { OpenPanel } from "./svg/openPanel";
import "./App.css";
// import AdminSection from "./pages/Admin/Sidebar";

function App() {
  const dispatch = useAppDispatch();
  const { user, error: requestError } = useAppSelector(
    (state: RootState) => state.userDetails
  );

  const { isSidePanelActive } = useAppSelector(
    (state: RootState) => state.sidePanelDetails
  );

  // const [activeTab, setActiveTab] = useState("search");

  const isMobileView =
    (window.innerWidth || document.documentElement.clientWidth) < 768;
  const showSidePanel = () => {
    if (isMobileView) {
      dispatch(setIsSidePanelActiveMobileView(true));
    } else {
      dispatch(setIsSidePanelActive(true));
    }
  };

  const hideSidePanel = () => {
    dispatch(setIsSidePanelActive(false));
  };
  useEffect(() => {
    if (!Object.keys(user).length) {
    dispatch(fetchUserDetails());
    dispatch(fetchHistory());
    dispatch(fetchFavorites());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (requestError?.code === 401) {
      window.open("/api/login", "_self");
    }
  }, [requestError]);

  const onTabSelect = (eventKey: string | null) => {
    let sidePanelState: SidePanelState;
    switch (eventKey) {
      case "search":
        sidePanelState = SidePanelState.search;
        break;
      case "qna":
        sidePanelState = SidePanelState.qa;
        break;
      case "admin":
        sidePanelState = SidePanelState.admin;
        break;
    }
    dispatch(setSidePanelState(sidePanelState!));
  };

  // const [isSettingOpen, setIsSettingOpen] = useState(false);

  const renderToggleIcon = () => {
    if (isSidePanelActive) {
      return (
        <span
          className="cursor-pointer"
          onClick={hideSidePanel}
          data-testid="close-panel"
        >
          <ClosePanel />
        </span>
      );
    }
    return (
      <span
        className="cursor-pointer"
        onClick={showSidePanel}
        data-testid="open-panel"
      >
        <OpenPanel />
      </span>
    );
  };
  return user.firstName ? (
    <div className="row homepage" data-testid="main-app">
      <div
        className={`left-panel ${
          isSidePanelActive
            ? // || isSettingOpen
              "col-8"
            : "col-12"
        }`}
      >
        <NavbarComponent
          userDetails={user}
          // setIsSettingOpen={setIsSettingOpen}
          // isSettingOpen={isSettingOpen}
        />
        <div>
          <Tabs
            className="top-nav-tabs"
            defaultActiveKey="qna"
            onSelect={onTabSelect}
          >
            {/* <Tab eventKey="search" title="Search">
              <Search />
            </Tab> */}
            {/*<Tab title=''></Tab> */}
            <Tab eventKey="qna" title="Q&A">
              <Questions />
            </Tab>
            {/* <Tab eventKey="admin" title="Admin">
                  <AdminSection/>
                </Tab> */}
            <Tab title={<>{renderToggleIcon()}</>}></Tab>
          </Tabs>
        </div>

        {requestError?.code === 403 && <ModalPopup show />}
      </div>
      <div
        className={`col-4 border right-panel ${
          isSidePanelActive ? "active" : "in-active"
        }`}
      >
        <SidePanel />
      </div>
      <SidePanelMobileView />
      {/* <Settings
            setIsSettingOpen={setIsSettingOpen}
            isSettingOpen={isSettingOpen} /> */}
    </div>
  ) : (
    <div className="loading-answer">
      <div
        className="mt-5 mx-auto spinner-border loader-color"
        role="status"
        data-testid="app-loader"
      />
    </div>
  );
}

export default App;
