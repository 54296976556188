const SINGLE_MESSAGE = 'single';

const MULTIPLE_MESSAGE = 'multiple';

const SINGLE_NO_RESPONSE_MESSAGE = 'Sorry but this is not the type of prompt I can reply to.';

const MULTIPLE_NO_RESPONSE_MESSAGE = 'Sorry but I was unable to answer some of your Prompts.';

const VIEW_PROMPTS_EXAMPLE = 'View Examples of Prompts';

const MAX_CHARACTERS = 1000;

const COMPANY = 'Company';

const PUBLIC = 'Public';

const CUSTOMER = 'Customer';

const CONFIDENTIAL = 'Confidential';

const EYESONLY = 'EyesOnly';

const ALLOWED_FILE_TYPES = ["pdf", "doc", "docx", "ppt", "pptx", "xlsx", "txt"];

export {
  MAX_CHARACTERS,
  MULTIPLE_MESSAGE,
  MULTIPLE_NO_RESPONSE_MESSAGE,
  SINGLE_NO_RESPONSE_MESSAGE,
  SINGLE_MESSAGE,
  VIEW_PROMPTS_EXAMPLE,
  COMPANY,
  PUBLIC,
  CUSTOMER,
  CONFIDENTIAL,
  EYESONLY,
  ALLOWED_FILE_TYPES
};
