const SIDE_PANEL_QUESTIONS = [
  {
    name: "Jira",
    questions: [
      {
        key: 1,
        value: "What are the common recurring errors experienced during lending and returning requests in the Integrated Library System (ILS)",
      },
      {
        key: 2,
        value: "What are the common challenges faced when editing collection web pages on ContentDM sites",
      },
      {
        key: 3,
        value: "Can you list any tickets related to software crashes or errors?",
      },
      {
        key: 4,
        value: "Are there any tickets regarding difficulties in accessing certain features?",
      },
      {
        key: 5,
        value: "Have there been any recent tickets related to account or login problems?",
      },
      {
        key: 6,
        value: "Are there any tickets indicating compatibility problems with certain devices or operating systems?",
      },
      // {
      //   key: 7,
      //   value: "Are there any tickets indicating compatibility problems with certain devices or operating systems?",
      // },
      // {
      //   key: 8,
      //   value: 'What is a Data Center in a box?',
      // },
      // {
      //   key: 9,
      //   value: 'What are the key tenets of the CTO Flywheel',
      // },
      // {
      //   key: 10,
      //   value: "What is the latest CTO showcase topic",
      // },
      // {
      //   key: 11,
      //   value:
      //     "What are the key objectives of the Rackspace Technical Community",
      // },
      // {
      //   key: 12,
      //   value:
      //     "Describe the operating model for Rackspace technical communities",
      // },
      // {
      //   key: 13,
      //   value:
      //     "What are the 3 pillars of FAIR Responsible AI ? Expand on each of the pillars with details.",
      // },
      // {
      //   key: 14,
      //   value:
      //     "Provide an overview, benefits and differentiators of Rackspace's Government services to help State and Federal organizations on-board to the Cloud.",
      // },
      // {
      //   key: 15,
      //   value: "How is FAIR helping customers with AI solutions ?",
      // },
    ],
  },
  // {
  //   name: 'Google Cloud Architecture',
  //   questions: [
  //     {
  //       key: 1,
  //       value: 'Explain the 6 pillars of Google CAF with details on each pillar',
  //     },
  //     {
  //       key: 2,
  //       value: 'What are the architecture patterns for streaming analytics.
  //        Be as detailed as possible with examples and technology choices on Google Cloud',
  //     },
  //     {
  //       key: 3,
  //       value: 'Provide a detailed summary and details in a couple paragraphs on zero trust
  //       architecture',
  //     },
  //     {
  //       key: 4,
  //       value: 'What the differences between using Cloud Data Proc vs Cloud Data Fusion',
  //     },
  //     {
  //       key: 5,
  //       value: 'What are the different migration patterns to Google Cloud',
  //     },
  //   ],
  // },
  {
    name: "Zendesk",
    questions: [
      {
        key: 1,
        value: "What are the tickets raised related to Cloud Security Risk"
      },
      {
        key: 2,
        value: "Are there any recurring themes or keywords in the ticket descriptions?"
      },
      {
        key: 3,
        value: "What are the issues created regarding public notes"
      },
      {
        key: 4,
        value: "Have there been any recent updates or changes that have led to an increase in tickets?"
      },
      {
        key: 5,
        value: "Are there any tickets indicating dissatisfaction with customer support or service?"
      },
    ],
  },
];

const SIDE_PANEL_FAQS = [
  {
    question: "How do I get access to ICE?",
    answer:
      "Access to ICE is being opened up to lots of different users, but still in the early stages. ICE will be available at this address you are using and will be an independent application.",
  },
  {
    question:
      "Are there any special commands or formatting I need to know to get the best responses from ICE?",
    answer:
      "We built ICE to be user friendly. Is nothing like writing code, so no special training or commands are needed. You just need to keep it conversational with ICE. Go to the text box, type your questions like in any regular chat and you will start getting answers. To get the best experience try providing ICE as much context as possible, be very specific about the subject and experiment with different type of questions.",
  },
  {
    question:
      "How does ICE work? What is happening behind the scenes that allow ICE to generate such accurate responses?",
    answer:
      "Behind the user interface you use to interact with ICE we have a generative AI that is capable of understanding your questions and generate logical responses. How we do that? We use a large language model. This is an artificial intelligence algorithm trained by an enormous amount of text from multiple sources, like websites, publications, documents, and books. This model uses all that information to learn how to construct sentences and the relations between words to provide a human like response. Finally, we provide the context of details within Rackspace, so the responses are related to our business and provide the help you need.",
  },
  {
    question:
      "How can I be sure that the information ICE gives me is reliable?",
    answer:
      "Think of ICE as your first step in accessing preliminary information and setting the bases for a deeper understanding of the area you are interested on. It is always recommended to continue asking questions that allow you to verify the source and the logic of the answer. Always contrast the responses from ICE with your judgment and, when needed, verify information with trusted sources.",
  },
  {
    question:
      "What are examples of applications of ICE in business? Where are you using it?",
    answer:
      "We are using ICE to help our employees to access information in a fast and easy way. The tool is capable to transform a normal text question into a query that will extract the necessary data to respond to the user. This data can be about any topic of Rackspace, like KPIs, company information, internal documents, Rackspace web site or the content stored in our wiki. A lot of the information will be added over time.",
  },
];

const PROJECT_DATA = [
  { name: "Project 1", value: "project1" },
  { name: "Project 2", value: "project2" },
];

const MODEL_DATA = [
  { name: "Model 1", value: "model1" },
  { name: "Model 2", value: "model2" },
];

const DUMMY_CHART_DATA = [
  {
    x: "Sep 2023",
    y: 226,
  },
  {
    x: "Oct 2023",
    y: 35,
  },
  {
    x: "Nov 2023",
    y: 52,
  },
  {
    x: "Dec 2023",
    y: 30,
  },
];

const MODEL_MONTHLY_COST_DATA = [
  {
    x: "202309",
    y: 226,
    z: 100,
  },
  {
    x: "202310",
    y: 35,
    z: 100,
  },
  {
    x: "202311",
    y: 52,
    z: 100,
  },
  {
    x: "202312",
    y: 30,
    z: 100,
  },
];

const DUMMY_PIECHART_DATA = [
  { name: "Codey", value: 0, fill: "#1f70f4" },
  { name: "Embeddings", value: 400, fill: "#12b6cc" },
  { name: "Text-Bison", value: 700, fill: "#f10699" },
];

const VERTEX_AI_LEGENDS = [
  {
    label: "Net Cost($)",
    color: "#55cee4",
  },
];

const VERTEX_AI_PROJECT_COST_LEGENDS = [
  {
    label: "Net Cost($)",
    color: "#737373",
  },
];

const COST_BY_SERVICE_LEGENDS = [
  {
    label: "Net Cost($)",
    color: "#226df6",
  },
];

const MODEL_MONTHLY_COST_LEGENDS = [
  {
    label: "Text-Bison",
    color: "#f10699",
  },
  {
    label: "Embeddings",
    color: "#12b6cc",
  },
  {
    label: "Codey",
    color: "#1f70f4",
  },
];

const PROMPT_TAB = "promptLibrary";
const FAQ_TAB = "faq";
const HISTORY_TAB = "history";
const HISTORY_SIDE_TAB = "HISTORY_SIDE_TAB";
const FAVOURITE_SIDE_TAB = "FAVOURITE_SIDE_TAB";
const RESPONSE_SPEED_FAST = "RESPONSE_SPEED_FAST";
const RESPONSE_SPEED_NORMAL = "RESPONSE_SPEED_NORMAL";
const RESPONSE_LENGTH_CONCISE = "RESPONSE_LENGTH_CONCISE";
const RESPONSE_LENGTH_VERBOSE = "RESPONSE_LENGTH_VERBOSE";
const RESPONSE_GEN_CREATIVITY_NONE = "RESPONSE_GEN_CREATIVITY_NONE";
const RESPONSE_GEN_CREATIVITY_SPRINKLING = "RESPONSE_GEN_CREATIVITY_SPRINKLING";
const RESPONSE_GEN_CREATIVITY_LIBERAL = "RESPONSE_GEN_CREATIVITY_LIBERAL";

export {
  SIDE_PANEL_FAQS,
  SIDE_PANEL_QUESTIONS,
  PROMPT_TAB,
  FAQ_TAB,
  HISTORY_TAB,
  HISTORY_SIDE_TAB,
  FAVOURITE_SIDE_TAB,
  RESPONSE_SPEED_FAST,
  RESPONSE_SPEED_NORMAL,
  RESPONSE_LENGTH_CONCISE,
  RESPONSE_LENGTH_VERBOSE,
  RESPONSE_GEN_CREATIVITY_NONE,
  RESPONSE_GEN_CREATIVITY_SPRINKLING,
  RESPONSE_GEN_CREATIVITY_LIBERAL,
  PROJECT_DATA,
  MODEL_DATA,
  DUMMY_CHART_DATA,
  MODEL_MONTHLY_COST_DATA,
  VERTEX_AI_LEGENDS,
  VERTEX_AI_PROJECT_COST_LEGENDS,
  COST_BY_SERVICE_LEGENDS,
  MODEL_MONTHLY_COST_LEGENDS,
  DUMMY_PIECHART_DATA,
};
