import { Navbar, Container } from "react-bootstrap";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
// import { Settings } from '../../svg/settings';
import { UserDetails } from "../../types";
import "./index.css";

type NavbarProps = {
  userDetails: UserDetails;
  // setIsSettingOpen: React.Dispatch<React.SetStateAction<boolean>>;
  // isSettingOpen: boolean;
};

function NavbarComponent(props: NavbarProps) {
  const {
    userDetails,
    // setIsSettingOpen,
    // isSettingOpen
  } = props;

  const { isSidePanelActive } = useAppSelector(
    (state: RootState) => state.sidePanelDetails
  );

  return (
    <Navbar
      expand="lg"
      className="bg-body-tertiary border-bottom position-sticky bg-navbar"
      fixed="top"
      data-testid="navbar"
    >
      <Container
        fluid
        className={`navbar-default ${
          isSidePanelActive ? "navbar-split-view" : ""
        }`}
      >
        <Navbar.Brand href="#home" className="logos">
          <div className="oclc-logo-desktop">
            <img src={"/oclc-logo-desktop.png"} alt="logo" height={50} />
          </div>
          <div className="oclc-logo-mobile">
            <img src={"/oclc-logo-mobile.png"} alt="logo" height={50} />
          </div>
        </Navbar.Brand>
        <span className="ms-auto">{`${userDetails.firstName} ${userDetails.lastName}`}</span>
        {/* <span className="cursor-pointer" onClick={() => setIsSettingOpen(!isSettingOpen)}> <Settings/> </span> */}
      </Container>
    </Navbar>
  );
}

export default NavbarComponent;
